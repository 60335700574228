import { AvailableRental } from "../available_rental/available_rental.model";

export interface EventDTO {
  id: string;
  name: string;
  date: string;
  min: number;
  max: number;
  created_at: string;
  is_locked: boolean;
  blocked: number;
  base_price: number;
  unified_rental_price: number;
  intro_count: number;
  type: number;
}

export interface EventRegistrationDTO {
  id?: string;
  event_id: string;
  user_id: string;
  name: string;
  surname: string;
  nickname: string;
  email: string;
  phone: string;
  note: string;
  status?: EventRegistrationStatusEnum;
  rejected_reason?: string;
  intro: boolean;
  created_at?: string;
}

export type EventRegistration = EventRegistrationDTO & {
  rentals: EventRegistrationRentals[];
}

export interface EventRegistrationRentalsDTO {
  id?: string;
  registration_id: string;
  rental_id: string;
}

export interface EventTypeDTO {
  name: string;
  icon: string;
  color: string;
}

export type EventRegistrationRentals = EventRegistrationRentalsDTO & {
  available_rentals: AvailableRental[];
  details?: AvailableRental;
}

export type ExtendedEvent = EventDTO & {
  registered_user_count?: number,
  approved_user_count?: number,
  is_registered?: boolean,
  registrations?: EventRegistration[],
  type?: EventTypeDTO,
};

export type Event = EventDTO & ExtendedEvent;

export enum EventRegistrationStatusEnum {
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  null = null,
}
